:root {
  --background-color: #ffffff;
  --text-color: #000000;
}


@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #181818;
    --text-color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --background-color: #ffffff;
    --text-color: #000000;
  }
}

body{
  margin:0;
}

.input-field{
  margin: 0 auto;
  width: 600px;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.tool-component{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div{
  box-sizing: border-box;
}